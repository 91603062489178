import React, { SVGAttributes } from 'react';

export function ChevronIconLeft(props: SVGAttributes<unknown>): JSX.Element {
  const { fill } = props;
  return (
    <svg width='6' height='11' viewBox='0 0 6 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.25714 0.937851C5.45908 1.16318 5.45865 1.52803 5.25618 1.75276L1.73459 5.66176L5.24667 9.58135C5.44859 9.80669 5.44813 10.1715 5.24565 10.3963C5.04316 10.621 4.71533 10.6205 4.51341 10.3951L0.818712 6.27173C0.746737 6.19145 0.689391 6.09587 0.650486 5.99104C0.61153 5.88608 0.591552 5.7736 0.59169 5.66004C0.591828 5.54648 0.612079 5.43407 0.65129 5.32922C0.69045 5.22451 0.74775 5.12941 0.81992 5.04935L4.5249 0.936788C4.72737 0.71205 5.0552 0.712525 5.25714 0.937851Z'
        fill={fill ?? '#242526'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.32206 0.865791C5.55975 1.13102 5.55925 1.56048 5.32093 1.82501L1.86425 5.66196L5.31159 9.50929C5.54927 9.77454 5.54873 10.204 5.31039 10.4685C5.07205 10.733 4.68616 10.7324 4.44849 10.4672L0.753791 6.34379C0.673316 6.25402 0.609242 6.1472 0.565742 6.02999C0.522185 5.91263 0.499847 5.78687 0.500001 5.6599C0.500155 5.53294 0.522799 5.40724 0.566641 5.29001C0.610426 5.17294 0.674494 5.06661 0.755188 4.97708L4.46015 0.864539C4.69847 0.600004 5.08436 0.600564 5.32206 0.865791ZM5.19143 1.68052C5.35805 1.49558 5.3584 1.19534 5.19222 1.00991C5.02604 0.824486 4.75626 0.824094 4.58965 1.00904L0.884947 5.12129C0.821301 5.1919 0.770474 5.27609 0.735939 5.36843C0.701359 5.4609 0.683499 5.56004 0.683377 5.66018C0.683256 5.76033 0.700875 5.85952 0.73523 5.95208C0.76954 6.04453 0.819869 6.12855 0.883343 6.19935L4.57833 10.3231C4.7445 10.5085 5.01428 10.5089 5.1809 10.324C5.34753 10.1391 5.34791 9.83884 5.18175 9.6534L1.66966 5.73382C1.63391 5.69391 1.63399 5.62931 1.66984 5.58951L5.19143 1.68052ZM1.61646 5.52988C1.58062 5.56968 1.52258 5.56962 1.48681 5.52975C1.45106 5.48985 1.45082 5.42491 1.48665 5.38511C1.52249 5.34531 1.58053 5.34537 1.6163 5.38524C1.65205 5.42514 1.6523 5.49008 1.61646 5.52988ZM1.61614 5.79329C1.65188 5.83319 1.65179 5.89779 1.61595 5.93757C1.58008 5.97734 1.52172 5.97757 1.48598 5.93767C1.45024 5.89776 1.45033 5.83317 1.48618 5.79338C1.52204 5.75362 1.5804 5.75338 1.61614 5.79329Z'
        fill={fill ?? '#242526'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.50823 0.698941C5.83116 1.05927 5.83054 1.63285 5.50667 1.99234L2.20033 5.66241L5.49779 9.34246C5.82068 9.70282 5.82002 10.2764 5.49611 10.6359C5.15824 11.0108 4.59909 11.0099 4.2623 10.634L0.567643 6.51067C0.465534 6.39676 0.385403 6.26259 0.331363 6.11698C0.277288 5.97128 0.249811 5.81593 0.250001 5.6596C0.250191 5.50327 0.278045 5.34799 0.332481 5.20244C0.38685 5.05706 0.466996 4.92341 0.569489 4.8097L4.27441 0.697207C4.61226 0.322198 5.1714 0.323098 5.50823 0.698941ZM1.41613 5.53386C1.39962 5.5085 1.39327 5.48146 1.39323 5.45808C1.39317 5.42894 1.40292 5.39342 1.43068 5.36259C1.43527 5.35749 1.44026 5.35266 1.4456 5.34814C1.47335 5.32467 1.51067 5.30965 1.55186 5.30969C1.56247 5.3097 1.57282 5.31071 1.58281 5.31261M1.58201 6.01062L1.50299 5.92243L1.48347 5.90065C1.45349 5.86718 1.43095 5.82919 1.41585 5.78891C1.39934 5.81433 1.39292 5.84153 1.39289 5.86514C1.39285 5.89466 1.40278 5.92979 1.42991 5.96008C1.4345 5.9652 1.43947 5.97004 1.44478 5.97457M1.4124 5.7793C1.41496 5.78047 1.41753 5.78161 1.42011 5.78271C1.42317 5.77848 1.42654 5.77431 1.43021 5.77023M1.4124 5.7793C1.40495 5.75753 1.39963 5.73516 1.39645 5.71254C1.39172 5.67885 1.39172 5.64448 1.39647 5.6108C1.39969 5.58806 1.40508 5.56547 1.41261 5.5436C1.41522 5.54242 1.41784 5.54127 1.42046 5.54015M1.52939 5.8595L1.54449 5.84598L1.52939 5.8595ZM1.56719 5.45763L1.55168 5.47155L1.56719 5.45763ZM1.53592 5.45736L1.55143 5.44344L1.53592 5.45736Z'
        fill={fill ?? '#242526'}
      />
    </svg>
  );
}
