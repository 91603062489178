import React from 'react';
import styled from 'styled-components';
import Imgix from 'shared/components/imgix';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ImageBanner } from 'shared/components/image-banner';
import { mediaQueries } from 'shared/styles';
import { tracker } from 'src/utils/analytics';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { BannerImageWrapper } from './banner-image-wrapper';

export type Banner = {
  _id: string;
  image: string;
  mobileImage: string;
  alt?: string | null;
  link?: string | null;
  openInNewTab?: boolean | null;
  position: number;
};

export type MenuImageBannerProps = {
  banners: Banner[] | null;
};

export const TEST_ID_MENU_IMAGE_BANNER = 'menu-image-banner';
export const TEST_ID_SCROLL_ITEM_CONTAINER = 'scroll-item-container';

const imageDefaults = {
  type: `img`,
  imgixParams: { fit: `crop`, fill: `solid`, fillColor: `#fff`, crop: [`edges`] },
};

export function MenuImageBanner({ banners }: MenuImageBannerProps): JSX.Element {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down(`sm`));
  const { dispensary } = useDispensary();

  if (!banners) {
    return <></>;
  }

  const carouselOptions = {
    draggable: banners.length > 1,
    loop: true,
    startIndex: 0,
    slidesToScroll: 1,
    banners,
  };

  return (
    <ImageBanner carouselOptions={carouselOptions}>
      <MainImageScrollContainer data-testid={TEST_ID_MENU_IMAGE_BANNER}>
        {banners.map((banner, index) => {
          const target =
            banner.openInNewTab === null || typeof banner.openInNewTab === 'undefined' || banner.openInNewTab
              ? '_blank'
              : '_top';
          return (
            <ScrollItem
              key={banner._id}
              data-testid={TEST_ID_SCROLL_ITEM_CONTAINER}
              isSingleBanner={banners.length === 1}
            >
              <BannerImageWrapper
                linkUrl={banner.link}
                rel={target === '_blank' ? 'noopener noreferrer' : null}
                target={target}
                onClick={() =>
                  tracker.imageBannerClicked({ dispensaryId: dispensary.id, dispensaryName: dispensary.name })
                }
              >
                <LazyMainImage
                  {...imageDefaults}
                  htmlAttributes={{ alt: banner.alt }}
                  src={isMobile ? `${banner.mobileImage}?ar=2.22:1` : `${banner.image}?ar=3:1`}
                  attributeConfig={{
                    src: `data-src`,
                    srcSet: `data-srcset`,
                    sizes: `data-sizes`,
                  }}
                  className={`product-image-${index} lazyload`}
                />
              </BannerImageWrapper>
            </ScrollItem>
          );
        })}
      </MainImageScrollContainer>
    </ImageBanner>
  );
}

const MainImageScrollContainer = styled.div`
  display: flex;
  margin-top: 14px;
`;

const ScrollItem = styled.div`
  min-width: 100%;
  display: flex;
  justify-content: center;

  @media ${mediaQueries.largePhone} {
    margin-right: ${({ isSingleBanner }) => (isSingleBanner ? '0' : '-44px')};
  }
`;

const LazyMainImage = styled(Imgix)`
  aspect-ratio: 3/1 auto;
  object-fit: contain;
  width: 100%;
  border-radius: 10px;
  @media ${mediaQueries.largePhone} {
    aspect-ratio: 2.22/1 auto;
    border-radius: 8px;
  }
  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
  &.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
  }
`;
