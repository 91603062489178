import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { mediaQueries } from 'shared/styles';

type BannerImageWrapperProps = {
  children: ReactNode;
  linkUrl: string | null | undefined;
  target: string | null | undefined;
  rel: string | null | undefined;
  onClick: () => void;
};

export function BannerImageWrapper({ children, linkUrl, target, rel, onClick }: BannerImageWrapperProps): JSX.Element {
  if (linkUrl) {
    return (
      <Link href={linkUrl} target={target} rel={rel} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return <Container>{children}</Container>;
}

const imageWrapperStyle = css`
  max-width: 1200px;
  width: 100%;
  margin: 0;
  padding: 10px 0;

  @media ${mediaQueries.largeDesktop} {
    margin: 0 25px;
  }

  @media ${mediaQueries.largePhone} {
    margin: 0 10px;
    padding: 10px 16px;
  }
`;

const Link = styled.a`
  ${imageWrapperStyle}
`;

const Container = styled.div`
  ${imageWrapperStyle}
`;
