import React, { SVGAttributes } from 'react';

export function ChevronIconRight(props: SVGAttributes<unknown>): JSX.Element {
  const { fill } = props;
  return (
    <svg width='6' height='11' viewBox='0 0 6 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.742862 10.3952C0.540924 10.1698 0.541352 9.80498 0.743818 9.58024L4.26541 5.67125L0.75333 1.75166C0.551411 1.52632 0.55187 1.16147 0.754354 0.93675C0.956838 0.712032 1.28467 0.712543 1.48659 0.93789L5.18129 5.06128C5.25326 5.14156 5.31061 5.23714 5.34951 5.34197C5.38847 5.44693 5.40845 5.55941 5.40831 5.67297C5.40817 5.78652 5.38792 5.89894 5.34871 6.00378C5.30955 6.10849 5.25225 6.20359 5.18008 6.28366L1.4751 10.3962C1.27263 10.621 0.944799 10.6205 0.742862 10.3952Z'
        fill={fill ?? '#242526'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.677944 10.4672C0.440247 10.202 0.44075 9.77253 0.679069 9.508L4.13575 5.67105L0.688406 1.82371C0.450731 1.55846 0.45127 1.129 0.689611 0.864495C0.927952 0.599984 1.31384 0.600585 1.55151 0.865838L5.24621 4.98922C5.32668 5.07899 5.39076 5.18581 5.43426 5.30301C5.47782 5.42037 5.50015 5.54613 5.5 5.6731C5.49985 5.80007 5.4772 5.92577 5.43336 6.04299C5.38957 6.16007 5.32551 6.2664 5.24481 6.35593L1.53985 10.4685C1.30153 10.733 0.915641 10.7324 0.677944 10.4672ZM0.808567 9.65249C0.641954 9.83743 0.641602 10.1377 0.80778 10.3231C0.973958 10.5085 1.24374 10.5089 1.41035 10.324L5.11505 6.21172C5.1787 6.14111 5.22953 6.05692 5.26406 5.96457C5.29864 5.87211 5.3165 5.77297 5.31662 5.67283C5.31674 5.57268 5.29913 5.47349 5.26477 5.38092C5.23046 5.28848 5.18013 5.20446 5.11666 5.13366L1.42167 1.00994C1.2555 0.824501 0.985724 0.824081 0.819097 1.009C0.652469 1.19393 0.652092 1.49417 0.818254 1.67961L4.33034 5.59919C4.36609 5.63909 4.36601 5.7037 4.33016 5.74349L0.808567 9.65249ZM4.38354 5.80313C4.41938 5.76333 4.47742 5.76339 4.51319 5.80326C4.54894 5.84315 4.54918 5.9081 4.51335 5.9479C4.47751 5.9877 4.41947 5.98764 4.3837 5.94777C4.34795 5.90787 4.3477 5.84293 4.38354 5.80313ZM4.38386 5.53972C4.34812 5.49981 4.34821 5.43522 4.38406 5.39543C4.41992 5.35567 4.47828 5.35543 4.51402 5.39534C4.54976 5.43525 4.54967 5.49984 4.51382 5.53963C4.47796 5.57939 4.4196 5.57963 4.38386 5.53972Z'
        fill={fill ?? '#242526'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.491769 10.6341C0.168843 10.2737 0.169459 9.70016 0.493328 9.34066L3.79967 5.6706L0.502216 1.99055C0.179323 1.63019 0.179983 1.05661 0.503886 0.697145C0.841765 0.322166 1.40091 0.323131 1.7377 0.699005L5.43236 4.82234C5.53447 4.93625 5.6146 5.07042 5.66864 5.21603C5.72271 5.36173 5.75019 5.51708 5.75 5.67341C5.74981 5.82973 5.72196 5.98501 5.66752 6.13057C5.61315 6.27595 5.533 6.4096 5.43051 6.52331L1.72559 10.6358C1.38774 11.0108 0.8286 11.0099 0.491769 10.6341ZM4.58387 5.79915C4.60038 5.82451 4.60673 5.85155 4.60677 5.87493C4.60683 5.90407 4.59708 5.93959 4.56932 5.97042C4.56473 5.97551 4.55974 5.98035 4.5544 5.98486C4.52666 6.00834 4.48933 6.02336 4.44814 6.02332C4.43753 6.02331 4.42718 6.0223 4.41719 6.0204M4.41799 5.32239L4.49701 5.41058L4.51653 5.43236C4.54651 5.46583 4.56906 5.50382 4.58415 5.54409C4.60066 5.51868 4.60708 5.49148 4.60711 5.46787C4.60715 5.43834 4.59722 5.40322 4.57009 5.37293C4.5655 5.36781 4.56053 5.36296 4.55522 5.35844M4.5876 5.55371C4.58504 5.55254 4.58247 5.5514 4.57989 5.55029C4.57683 5.55453 4.57346 5.55869 4.56979 5.56278M4.5876 5.55371C4.59505 5.57547 4.60037 5.59784 4.60355 5.62047C4.60828 5.65416 4.60828 5.68852 4.60353 5.72221C4.60031 5.74495 4.59492 5.76754 4.58739 5.78941C4.58478 5.79059 4.58216 5.79174 4.57954 5.79286M4.47061 5.47351L4.45551 5.48703L4.47061 5.47351ZM4.43281 5.87537L4.44832 5.86146L4.43281 5.87537ZM4.46408 5.87565L4.44857 5.88957L4.46408 5.87565Z'
        fill={fill ?? '#242526'}
      />
    </svg>
  );
}
